/* General Layout */
.poll-container {
    min-height: 100vh;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Question Header */
.question-header {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #000;
}

.question-title {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
}

/* Choice Buttons */
.choice-button {
    border-radius: 30px;
    text-transform: capitalize;
    margin: 5px;
    padding: 10px 10px; /* Reduced horizontal padding for smaller width */
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    color: #000;
    font-size: 0.8rem; /* Adjust font size if needed */
    max-width: 130px !important; /* Reduced maximum width */
    min-width: 70px !important;
    width: auto; /* Allow the width to adjust based on content */
    text-align: center; /* Ensure text is centered */
    box-sizing: border-box; /* Include padding and border in the width calculation */
}

/* Choices Container */
.choices-container {
    display: flex;
    height: 50px;
    
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.choice-button:hover {
    background-color: #e0e0e0;
}

.choice-button.selected {
    background-color: #000;
    color: #fff;
}

/* Navigation Buttons */
.control-button {
    background-color: #000 !important; /* Ensure the button is black */
    color: #fff !important; /* Ensure the text is white */
    border-radius: 32px !important; /* Optional: Rounded corners */
    padding: 14px 28px !important; /* Optional: Padding */
    text-transform: none; /* Optional: Disable uppercase text */
    font-weight: bold; /* Optional: Bold text */
}

.control-button:hover {
    background-color: #333 !important; /* Darker black on hover */
}

.control-button:disabled {
    background-color: #ddd !important; /* Light gray when disabled */
    color: #aaa !important; /* Dimmed text when disabled */
}

.progress-bar-container {
    flex: 1; /* Take up available space */
    display: flex;
    align-items: center;
    gap: 10px; /* Space between progress bar and text */
}

.progress-bar {
    width: 75%; /* Make the bar as wide as its container */
    height: 10px;
    border-radius: 5px;
    appearance: none;

    /* Customize the progress bar */
    &::-webkit-progress-bar {
        background-color: #a8a3a3;
        border-radius: 10px;
    }

    &::-webkit-progress-value {
        background-color: #EC5264;
        border-radius: 10px;
    }

    &::-moz-progress-bar {
        background-color: #EC5264;
        border-radius: 10px;
    }
}

.progress-text {
    font-weight: bold;
    font-size: 1rem;
    color: #000;
}

.control-buttons-container {
    display: flex;
    gap: 20px; /* Space between buttons */
    justify-content: center;
    align-items: center;
    flex: 0.5; /* Take less space than the progress bar */
}

