.mytest_maindiv {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
}

.mytest_col {
    display: flex;
    width: 330px;
    padding: 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 40px;

    /* Large Pop Up Drop */
}

.mytest_emptycol {
    display: flex;
    width: 330px;
    padding: 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 40px;

    /* Large Pop Up Drop */
}

.mytest_infocard {
    display: flex;
    width: 330px;
    padding: 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 40px;
    background: var(--MAIN-BG, #F0F0F0);

    /* Large Pop Up Drop */
    box-shadow: 0px 5px 60px 30px rgba(0, 0, 0, 0.07);
}

.mytest_pollcard {
    display: flex;
    height: 52px;
    padding: 10px 14px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 40px;
    background: var(--LGBG, #E8E8E8);
}

.mytest_pollcard_clicked {
    display: flex;
    height: 52px;
    padding: 10px 14px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 40px;
    background: var(--Solid-CTA, #9C52F2);
    color: var(--MAIN-BG, #F0F0F0);

    /* Body */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
}