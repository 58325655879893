.results_title_card {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 50px;
    padding-right: 33px;
    width: 900px;
    flex-direction: column !important;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    border-radius: 40px;
    background: var(--MAIN-BG, #F0F0F0);

    /* Large Pop Up Drop */
    box-shadow: 0px 5px 60px 30px rgba(0, 0, 0, 0.05);
}

.results_score_container {
    display: flex;
    flex-direction: column !important; /* Stack children vertically */
    gap: 20px; /* Space between the cards */
}