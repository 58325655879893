.choice-content img {
    max-width: 400px;
    max-height: 400px;
    display: block;
    margin: 0 auto;
}

.hover-effect:hover {
    opacity: 0.75;
    cursor: pointer;
}