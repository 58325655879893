/* ==========================================================================
   Base Layout & Container Styles
   ========================================================================== */
.genomics-container {
    margin: 0 auto;
    padding: 150px 0;
    background: #f8f9fa;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.genomics-container.processing {
    padding: 40px 0;
}

.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.cool-gradient {
    background: linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* ==========================================================================
   Typography & Text Styles
   ========================================================================== */
.genomics-container .red {
    font-size: 60px;
    font-weight: 700;
    line-height: 64px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-header {
    font-size: 32px;
    margin-bottom: 40px;
}

/* ==========================================================================
   Hero Section Styles
   ========================================================================== */
.hero-section {
    display: flex;
    gap: 60px;
    justify-content: space-between;
}

.hero-content {
    width: 570px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.hero-image {
    width: 570px;
    height: 308px;
    object-fit: cover;
    border-radius: 60px;
}

.hero-section.upload-mode .hero-content {
    width: 570px;
}

/* ==========================================================================
   HIPAA Notice Styles
   ========================================================================== */
.hipaa-notice {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 40px 0;
    margin-bottom: 40px;
}

/* ==========================================================================
   Results Page Styles
   ========================================================================== */
.results-header {
    margin-top: -40px;
    margin-bottom: 40px;
}

.results-header h1.red {
    font-size: 64px;
    font-weight: 700;
    line-height: 64px
}

.category-section {
    margin-bottom: 40px;
    position: relative;
}

.category-section h2 {
    padding: 0px 25px 0px 25px;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #000;
    line-height: 33.6px;
  }

.category-divider {
    width: 100%;
    height: 1px;
    background: #878787;
    margin: 50px 0;
}

.results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
    gap: 28px;
}

.result-card {
    gap: 10px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}
  
.result-card:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 30px 15px rgba(0, 0, 0, 0.15);
}

/* ==========================================================================
   Traits Grid & Cards
   ========================================================================== */
.base-card {
    padding: 25px;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 5px 45px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.base-card h3 {
    font-size: 24px;
    color: black;
    margin: 0;
    padding: 0;
    line-height: 100%;
    font-weight: 700;
}

.traits-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.trait-card {
    height: 200px;
    justify-content: center;
}

.trait-card p {
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 100%;
    font-size: 18px;
}

.trait-card h3 {
    font-size: 24px;
    color: black;
    margin: 0;
    padding: 0;
    line-height: 100%;
  }

/* ==========================================================================
   Upload Section Styles
   ========================================================================== */
.upload-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    gap: 26px;
    width: 100%;
    height: 322px;
    border: 1px solid #000000;
    border-radius: 40px;
    margin: 40px 0;
}

.upload-icon {
    width: 48px;
    height: 48px;
}

/* Drag & drop file to upload */
.upload-box h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: black;
    line-height: 100%;
}

.upload-box p {
    font-size: 24px;
    margin: 0;
    color: black;
}

.upload-buttons {
    display: flex;
    gap: 36px;
}

/* ==========================================================================
   Button Styles
   ========================================================================== */

/* Base button styles */
.btn {
    height: 58px;
    padding: 18px 36px 18px 36px;
    border-radius: 32px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    display: flex;           
    align-items: center;
    justify-content: center;
  }
  
  /* Default purple/white theme */
  .btn.btn-default {
    background: linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%);
    color: white;
  }
  
  .btn.btn-default:hover {
    background: black;
    transition: all ease-out;
  }
  
  /* Cancel button (keeps unique styling) */
  .btn.cancel-button {
    background: #E7E7E7;
  }
  
  .btn.cancel-button:hover {
    background: black;
    color: white;
  }

/* ==========================================================================
   Terms and Conditions Popup
   ========================================================================== */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.terms-popup {
    width: 800px;
    height: 800px;
    padding: 32px 0 0 0;
    background: #f8f9fa;
    border-radius: 32px;
    gap: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.terms-popup h2 {
    padding: 0 32px;
    margin: 0;
    font-size: 32px;
    font-weight: bold;
}

.terms-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 32px;
}

.terms-section {
    margin-bottom: 24px;
}

.terms-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.terms-buttons {
    padding: 24px 32px;
    display: flex;
    border-radius: 32px;
    gap: 16px;
    justify-content: flex-end;
    background: #f8f9fa;
}

/* ==========================================================================
   Trait Details Popup
   ========================================================================== */
.trait-popup {
    width: 800px;
    padding: 32px 0 0 0;
    background: #f8f9fa;
    border-radius: 32px;
    gap: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.trait-popup h2 {
    padding: 0 32px;
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.trait-content {
    overflow-y: auto;
    padding: 32px;
}

.trait-information {
    margin-top: 100px;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
}

.trait-buttons {
    padding: 24px 32px;
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
}

/* ==========================================================================
   Processing State Styles
   ========================================================================== */
.progress-tracker {
    display: flex;
    align-items: center;
    gap: 16px;
}

.progress-step {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #666;
    font-weight: 700;
    font-size: 24px;
}

.progress-step .active {
    background: linear-gradient(
        90deg,
        #8A2BE2 0%,
        #f8f9fa 50%,
        #8A2BE2 100%
    );
    background-size: 200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shimmer 3s linear infinite;
    font-weight: 1000;
}

.progress-arrow {
    width: 28px;
    height: 28px;
}

.processing-status h2 {
    font-size: 65px;
    font-weight: bold;
    line-height: 1.2;
}

.processing-status p {
    font-size: 18px;
    color: #666;
}

.processing-main-box {
    width: 100%;
    max-width: 1200px;
    height: 500px;
    gap: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.processing-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    z-index: 1;
}

.processing-left-content {
    width: 858px;
    height: 406px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.processing-description {
    font-size: 18px;
    color: black;
    max-width: 800px;
}

/* ==========================================================================
   Percentile Bar Styles
   ========================================================================== */
.percentile-bar {
    height: 10px;
    width: 100%;  /* CHANGED: make bar full width */
    background: #E5E7EB;
    border-radius: 20px;
    position: relative;
    margin-bottom: 65px;
}

.percentile-fill {
    width: 100%; /* CHANGED: make bar full width */
    height: 100%;
    background: linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%);
    border-radius: 4px;
    position: relative;
  }

.percentile-marker {
    position: absolute;
    right: -10px;
    top: -30px;
    color: #9C52F2;
    font-size: 24px;
}

.percentile {
    font-size: 48px;
    color: #9C52F2;
    font-weight: bold;
    margin-right: 8px;
}

.percentile-text {
    font-size: 18px;
    color: #8F8F8F;
    line-height: 21.6px;
}

/* ==========================================================================
   Miscellaneous UI Elements
   ========================================================================== */
.bottom-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    font-size: 18px;
}

.cancel-link {
    background: none;
    border: none;
    color: #666;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
}

.meantime-section {
    margin-top: 80px;
}

.meantime-section h2 {
    font-size: 36px;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 39.6px;
    width: 500px;
}

.meantime-section p {
    line-height: 16px;
    font-size: 18px;
    color: black;
    margin-bottom: 24px;
}

.meantime-section .btn {
    display: inline-flex;
    text-decoration: none;
    margin-bottom: 80px;
}

/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes shimmer {
    0% {
        background-position: -200% center;
    }
    100% {
        background-position: 200% center;
    }
}

.upload-icon.spinning {
    animation: spin 2s linear infinite;
}

/* ==========================================================================
   Responsive Styles
   ========================================================================== */
@media (max-width: 1200px) {
    .hero-section {
        flex-direction: column;
        align-items: center;
    }

    .hero-content,
    .hero-image {
        width: 100%;
        max-width: 570px;
    }

    .traits-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

/* ==========================================================================
   Transitions
   ========================================================================== */
.hero-content, 
.hero-image, 
.hipaa-logo {
    transition: all 0.3s ease-out;
}