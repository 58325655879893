.page-container {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 220px 0;
    background: #f8f9fa;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  
  .page-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    position: relative;
  }
  
  .landing-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .left-section {
    width: 600px;
    position: relative;
    z-index: 10;
  }
  
  .logo-container {
    gap: 80px;
    align-items: center;
    margin-top: 120px;
  }

  .logo-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; 
    gap: 150px;
  }

  /* Base card mini */

  .base-card-mini {
    padding: 20px;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 5px 45px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
  }
  
  .base-card-mini:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0px 8px 30px 15px rgba(0, 0, 0, 0.15);
  }
  
  .base-card-mini h3 {
    font-size: 24px;
    color: black;
    margin: 0;
    padding: 0;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  .base-card-mini p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color: black;
    flex: 1;
    display: flex;
    align-items: center;
  }

  /* Base card long */

  .base-card-long {
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 5px 45px 15px rgba(0, 0, 0, 0.15);
    width: 850px;
    min-height: 80px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    cursor: pointer;
  }

  .base-card-long h3 {
    font-size: 24px;
    color: black;
    margin: 0;
    padding: 0;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .base-card-long p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color: black;
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .base-card-long:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 30px 15px rgba(0, 0, 0, 0.15);
  }
  