:root {
    font-family: 'Inter', sans-serif;
    --Solid-CTA: #9C52F2;
    --Main-BG: #f8f9fa;
    --LG-BG: #E8E8E8;
    --Line: #878787;
    --Light-Text: #8F8F8F;
  }

@font-face {
    font-family: 'Union';
    src: url('./fonts/Union-Regular.eot');
    src: local('Union Regular'), local('Union-Regular'),
        url('./fonts/Union-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Union-Regular.woff2') format('woff2'),
        url('./fonts/Union-Regular.woff') format('woff'),
        url('./fonts/Union-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Union';
    src: url('./fonts/Union-BoldItalic.eot');
    src: local('Union Bold Italic'), local('Union-BoldItalic'),
        url('./fonts/Union-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Union-BoldItalic.woff2') format('woff2'),
        url('./fonts/Union-BoldItalic.woff') format('woff'),
        url('./fonts/Union-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Union';
    src: url('./fonts/Union-Italic.eot');
    src: local('Union Italic'), local('Union-Italic'),
        url('./fonts/Union-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Union-Italic.woff2') format('woff2'),
        url('./fonts/Union-Italic.woff') format('woff'),
        url('./fonts/Union-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Union';
    src: url('./fonts/Union-Bold.eot');
    src: local('Union Bold'), local('Union-Bold'),
        url('./fonts/Union-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Union-Bold.woff2') format('woff2'),
        url('./fonts/Union-Bold.woff') format('woff'),
        url('./fonts/Union-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Then apply it to your app */


body {
    background: #f8f9fa;
    font-family: 'Inter', sans-serif;
}

.ellipsis-2-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    overflow: hidden;
}

.ellipsis-3-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    overflow: hidden;
}

.black {
    font-family: 'Inter', sans-serif; /* Clean sans-serif font */
    font-weight: 400; /* Bold */
    color: #000; /* Black */
    margin: 0;
    padding: 0;
}

hr {
    border: 0;
    height: 1px;
    background: var(--Line, #878787);
    margin: 20px 0 80px 0;
}

h1 {
    font-size: 52px;
    font-weight: 700;
}

h2 {
    font-size: 32px;
    font-weight: 700;
}

h4 {
    font-size: 20px;
    font-weight: 700;
}

.h2-size {
    font-size: 52px;
    font-weight: 700;
}

.p-size {
    font-size: 21px;
}

.red {
    font-family: 'Union'; /* Custom font and fallbacks */
    font-size: 36px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: 110%; /* Matches 39.6px for 36px font size */
    background: linear-gradient(90deg, #7F24FF 0%, #9C52F2 100%); /* Horizontal gradient */
    -webkit-background-clip: text; /* Clips gradient to the text */
    -webkit-text-fill-color: transparent; /* Makes text transparent to reveal gradient */
    margin: 0;
    padding: 0;
}

.grey {
    font-family: 'Union'; /* Contemporary sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 36px; /* Adjust based on your layout */
    color: grey; 
    margin: 0;
    padding: 0;
}

.dark-button {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    font-family: 'Union', sans-serif; /* Use a clean sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 16px; /* Adjust font size */
    padding: 12px 24px; /* Padding for a balanced look */
    border: none; /* Remove border */
    border-radius: 24px; /* Rounded edges */
    text-align: center; /* Center the text */
    cursor: pointer; /* Pointer cursor on hover */
    display: inline-block; /* Ensure button-like behavior */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.dark-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
}

.light-button {
    background-color: #f8f9fa; /* Light gray background */
    color: rgb(61, 58, 58); /* Black text */
    font-family: 'Inter', sans-serif; /* Use a clean sans-serif font */
    font-weight: 400; /* Semi-bold */
    font-size: 16px; /* Adjust font size */
    padding: 12px 24px; /* Padding for a balanced look */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 32px !important; /* Rounded edges */
    text-align: center; /* Center the text */
    cursor: pointer; /* Pointer cursor on hover */
    display: inline-block; /* Ensure button-like behavior */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.light-button:hover {
    background-color: #e6e6e6; /* Slightly darker gray on hover */
}

.link {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits the color from the parent element */
    font-weight: 400;
}

.popup-div {
    display: flex;
    height: 52px;
    padding: 1px 1px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.lr-div {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}

.fabulous-button {
    border-radius: 32px !important;
    background: var(--Horizontal-Gradient, linear-gradient(90deg, rgba(151, 73, 242, 0.30) 0%, #7F24FF 100%));  
    color: var(--MAIN-BG, #F0F0F0);

    /* Body */
    font-family: Inter;
    font-size: 18px; /* Increase font size for larger text */
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* Adjust line height proportionally */

    
    min-width: 150px; /* Ensure a minimum width */
    min-height: 50px; /* Ensure a minimum height */

    /* Center text */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none; /* Removes the focus outline */
    box-shadow: none; /* Removes any potential default shadow */
    border: transparent;
}

/* added this class for nav bar, had to restyle*/
.nav-button {}

.nav-button-selected {
    border-radius: 32px !important;
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    color: var(--Main-BG, #F0F0F0);
    background: var(--Solid-CTA, #9C52F2);
    border: 1px solid transparent;
}

.nav-button-default {
    border-radius: 32px !important;
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    background: var(--LG-BG, #9C52F2);
    border: 1px solid transparent;
}

.nav-button-default:hover {
    border: 1px solid var(--Solid-CTA, #9C52F2);
}   

.white-button {
    border-radius: 32px !important;
    border: 1px solid var(--Outline, #878787);
     /* Body */
     font-family: Union;
     font-size: 18px; /* Increase font size for larger text */
     font-style: normal;
     font-weight: 400;
     line-height: 120%; /* Adjust line height proportionally */
 
     min-width: 150px; /* Ensure a minimum width */
     min-height: 50px; /* Ensure a minimum height */
 
     /* Center text */
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
}

.popup-card {
    display: flex;
    width: 800px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: transparent;
}

.loading-screen {
    position: fixed; /* Ensures the spinner stays in one place */
    top: 0;
    left: 0;
    width: 100vw; /* Full screen width */
    height: 100vh; /* Full screen height */
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
}


.active-tag .tag-link {
    font-weight: bold; /* Bold only for the active tag */
    color: black; /* Ensure the active tag's color remains consistent */
}

.topbar-div {
    display: flex;
    width: 1200px;
    max-width: 1440px;
    padding: 20px 0px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
}

.settings-button {
    background-color: #f8f9fa; /* Light grey background */
    border: 1px solid #ced4da;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.settings-button:hover {
    background-color: #e9ecef; /* Slightly darker grey on hover */
}

.testflexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    gap: 20px;

}

/* New PollList styles */
.poll-list-container-nbs {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    border-radius: 10px;
}

.tags-column {
    position: relative;
}

.sticky-header {
    position: sticky;
    margin-top: 100px;
    top: 80px;
}

.tags-list {
    list-style-type: none;
    padding: 0;
}

.tag-item {
    margin-bottom: 10px;
}


/* New explore.jsx top styles */

.home-container-nbs{
    display: flex;
    justify-content: center;
}

.content-wrapper-nbs{
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    gap: 20px;
    margin: 80px;
}

.hero-section {
    display: flex;
    align-items: center;
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    width: 100%;
    border-radius: 40px;
}

.hero-text-nbs {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    gap: none;
}

.subjects-section {
    margin-top: 80px;
}

@media (max-width: 768px) {
    .hero-section {
        grid-template-columns: 1fr;
    }

    .hero-image img {
        width: 100%;
    }
}