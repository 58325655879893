/* Base Layout & Container Styles */
.about-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 220px 0;
    background: #f8f9fa;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  
  .about-content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }
  
  /* Hero section styles */
  .who-we-are-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .who-we-are-content {
    width: 600px;
    height: 380px;
    position: relative;
    z-index: 10;
  }
  
  .gradient-title {
    font-size: 64px;
    font-weight: 700;
    line-height: 89.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: -5px;
  }
  
  .description-text {
    font-size: 36px;
    font-weight: 700;
    line-height: 36.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

/* Contribution */

.section-base {
  display: flex;
  align-items: center;
  gap: 80px;
  margin-top: 250px;
}

.section-text {
  font-size: 18px;
  line-height: 21.6px;
  color: black;
  margin-top: -10px;
}

.section-title {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 24px;
}

/* Specific section styles */
.contribution-content {
  flex: 1;
}

.privacy-features {
  display: flex;
  gap: 40px;
  margin-top: 24px;
}

.privacy-feature {
  display: flex;
  align-items: center;
  gap: 8px;
}

.feature-text {
  font-size: 18px;
  color: #7F24FF;
}

/* How it works section */
.steps-section {
  padding: 80px 0px;
}

.steps-container {
  display: flex;
}

.step-card {
  flex: 1;
  border-radius: 50px 0 0 0;
  background: #f8f9fa;
  position: relative;
}

/* Divider line between cards */
.step-card:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  height: 115%;
  width: 1px;
  background: #878787;
}

.step-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid transparent;
  background:
      linear-gradient(#f8f9fa, #f8f9fa) padding-box,
      linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%) border-box;
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 700;
  color: #9C52F2;
  margin-right: 10px;
}

.step-card h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  color: black;
  margin: 0;
}

.step-card p {
  font-size: 18px;
  height: 21.6px;
  color: black;
  margin: 0;
}